import React from "react";
import "./ourStore.css";

const OurStore = () => {
  return (
    <div className="our-container scroll" id="ourStory">
      <div className="our-box">
        <div className="our-title-box">
          <p className="our-title">
            Our <p>Story</p>
          </p>
        </div>
        <div className="card-box">
          <div className="card-box-img">
            <img alt="" src="/images/rectangle81.png" />
            <p className="card-box-text">
              NINJA-PIXELS began as a{" "}
              <span className="card-text-name">fun experiment </span>
              in the Ninja Dojo and we decided to build upon this cool idea of
              free NFTs
            </p>
          </div>
          <div className="card-box-middle-img">
            <img alt="" src="/images/rectangle84.png" />
          </div>
          <div className="card-box-img2">
            <img
              alt=""
              src="/images/rectangle82.png"
              className="card-button-img-1"
            />
            <img
              alt=""
              src="/images/rectangle83.png"
              className="card-button-img-2"
            />
            <div className="card-box-img2-text">
              <p className="card-box-text-right">
                Each NINJA-PIXEL is generated with attributes from{" "}
                <span className="card-text-click">Ninja Weapon</span>,{" "}
                <span className="card-text-name">Hair</span>,{" "}
                <span className="card-text-name">Suit</span> and others
              </p>
              <p className="card-box-text-right">
                We introduced $PIXEL to distribute NINJA-PIXELS to as many
                humans as possible
              </p>
            </div>
          </div>
          <div className="card-button">
            <div className="card-button-img">
              <img
                alt=""
                src="/images/rectangle83.png"
                className="card-button-img-1"
              />
              <img
                alt=""
                src="/images/rectangle82.png"
                className="card-button-img-2"
              />
              <p className="card-box-text-button">
                You’ll be able to redeem 1$PIXEL for 1NINJA-PIXEL
                <br />
                <br />
                <p className="card-text-name-battle">
                  Get $PIXEL by taking part in $NINJA-XMAS, interacting within
                  Ninja Protocol ecosystem or buying on $PIXEL/$NINJA live Serum
                  market
                </p>
              </p>
            </div>
          </div>
        </div>
        {/* <div className='card-button'>
          <div className='card-button-img'>
            <img alt='' src='/images/rectangle83.png' />
            <p className='card-box-text-button'>
              $NINJA holders will get NINJA-PIXELS airdropped based on their
              holdings
              <p className='card-text-name-battle'>
                Legendary <span className='text-number'>- 10</span>
              </p>
              <p className='card-text-name'>
                Ninja Warrior <span className='text-number'>- 3</span>
              </p>
              <p className='card-text-click'>
                <a href='# '>
                  Ninja Apprentice <span className='text-number'>- 1</span>
                </a>
              </p>
              <p className='text-button'>
                Everybody else will have to try their luck during the official
                mint
              </p>
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurStore;
