import React from "react";
import "./roadMap.css";

const RoadMap = () => {
  return (
    <div className="road-container scroll" id="roadMap">
      <span className="space-road-map" />
      <div className="road-title">
        <p className="road-title-text">
          Road<p>map</p>
        </p>
      </div>
      <div className="road-box">
        <div className="road-img-box">
          <div className="fire-shuricen">
            <img src="/images/Fire_Kasai_Shurikens.png" alt="" />
          </div>
          <div className="vector-img">
            <img src="/images/Vector.png" alt="" />
          </div>
          <div className="shuricen">
            <img src="/images/Kasai_Shurikens_noflame 1.png" alt="" />
          </div>
          <div className="img1">
            <img src="/images/Ellipse 20.png" alt="" />
          </div>
          <div className="img2">
            <img src="/images/Ellipse 19.png" alt="" />
          </div>
          <div className="img3">
            <img src="/images/Ellipse 22.png" alt="" />
          </div>
          <div className="img4">
            <img src="/images/Ellipse 24.png" alt="" />
          </div>
          <div className="text text-1">
            <p className="text-date">Q4/2021</p>
            <p>NINJA-marketplace listing after a smooth mint</p>
          </div>
          <div className="text text-2">
            <p className="text-date">Q1/2022</p>
            <p>Layout foundation for PvP PIXEL-GAME</p>
          </div>
          <div className="text text-3">
            <p className="text-date">Q2/2022</p>
            <p>PIXEL-GAME beta</p>
          </div>
          <div className="text text-4">
            <p className="text-date">Q3/2022 and beyond</p>
            <p>
              Continue to expand the NINJA-PIXELS world and integrate it further
              into the Ninja Protocol ecosystem
            </p>
          </div>
          {/* <div className="roadmap-footer">
            <div className="bottom-text">
              <p>
                We are proud to announce the official partners of NINJA-PIXELS
              </p>
              <p>
                They believe in our project, that’s why they fund every single
                one of our competitors
              </p>
            </div>
            <div className="road-footer-img">
              <img src="/images/VC_G_W 1.png" alt="" />
              <img src="images/VC_C_W 1.png" alt="" />
              <img src="/images/VC_V_W 1.png" alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
