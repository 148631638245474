import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import * as anchor from "@project-serum/anchor";
import "./mint.css";

import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "../../candy-machine";



const programUuid = process.env.REACT_APP_CANDY_MACHINE_PROGRAM_ID;

const network = process.env.REACT_APP_SOLANA_NETWORK;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);


const txTimeout = 30000; // milliseconds (confirm this works for your project)

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here



const Mint =() => {
  const [balance, setBalance] = useState();
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const
        candyMachine = await getCandyMachineState(wallet, programUuid, connection);

      setItemsAvailable(candyMachine.state.itemsAvailable);
      setItemsRemaining(candyMachine.state.itemsRemaining);
      setItemsRedeemed(candyMachine.state.itemsRedeemed);

      setIsSoldOut(candyMachine.state.itemsRemaining === 0);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      if (!wallet) {
        setAlertState({
          open: true,
          message: "Connect your wallet to mint",
          severity: "info"
        })
      return
      }
      if (isSoldOut) {
        setAlertState({
          open: true,
          message: "Sold out",
          severity: "error"
        })
        return
      }
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        console.log(candyMachine)
        const mintTxId = await mintOneToken(
          candyMachine,
          wallet.publicKey,
            connection
        );
        console.log(mintTxId)
        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          txTimeout,
          connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error) {
      console.error(error)
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet]);

  useEffect(refreshCandyMachineState,[wallet]);

  return (
      <div className={"mint scroll"} >
    <main>

      <div className={"mint-container"}>
        <div className={"mint-info"}>
          {wallet && <p>Total Available: {itemsAvailable}</p>}

          {wallet && <p>Redeemed: {itemsRedeemed}</p>}

          {wallet && <p>Remaining: {itemsRemaining}</p>}
        </div>
        <button
            className={"mint-button"}
            onClick={onMint}
            variant="contained"
        >
          {!wallet ? 'MINT' : isSoldOut ? "SOLD OUT" : isMinting ? <CircularProgress /> : "MINT"}
        </button>
      </div>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
      </div>
  );
};

const renderCounter = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};
export default Mint;
