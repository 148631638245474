import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const location = useLocation();

  return (
    <div
      className={
        location.pathname === "/"
          ? "footer-container scroll"
          : "rearities-page-footer"
      }
      id="social"
    >
      <div className="footer-img">
        <div className="footer-logo">
          <div className="footer-logo-box">
            <a href="/">
              <img
                alt=""
                src="/images/footer-logo.png"
                className="footer-logo-img"
              />
            </a>
          </div>
        </div>
        <div className="footer-icon">
          <div className="footer-icon-box">
            <Link
              target="_blank"
              to={{
                pathname: "https://twitter.com/NinjaProtocol",
              }}
            >
              <img className="web-sites" alt="" src="/images/twit.png" />
            </Link>
            <Link
              target="_blank"
              to={{
                pathname: "https://discord.gg/ninjaprotocol",
              }}
            >
              <img className="web-sites" alt="" src="/images/discord.png" />
            </Link>
          </div>
        </div>
      </div>
      <p className="footer-text">
        NINJA-PIXELS is a collection of 10,000 unique NFTs on Solana blockchain
        that are completely free with ZERO mint cost and ZERO royalties
      </p>
    </div>
  );
};

export default Footer;
