export const raritiesBakground = [
  {
    image: "/rarities-img/rarites1.png",
    title: "Blood Skies",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites2.png",
    title: "Calm Skies",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites3.png",
    title: "Clear",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites4.png",
    title: "Glowing Skies",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites5.png",
    title: "Luminous",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites6.png",
    title: "Peaceful Seigaiha",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites7.png",
    title: "Sinister",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites8.png",
    title: "Sundown Seigaiha",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites9.png",
    title: "Sunrise Seigaiha",
    vector: "/rarities-img/Common-Vector.png",
  },
];

export const raritiesSuit = [
  {
    image: "/rarities-img/rarites10.png",
    title: "Brown Noumin Habit",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites11.png",
    title: "Grey Noumin Habit",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites12.png",
    title: "Black Shozoko",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites13.png",
    title: "Red Shozoko",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites14.png",
    title: "Amaterasu Ninja Suit",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites15.png",
    title: "Tsukyomi Ninja Suit",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites16.png",
    title: "Karate-Gi",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites17.png",
    title: "Kikai Ninja Suit",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites18.png",
    title: "Jeet Kune Do",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites19.png",
    title: " Kori Ninja Suit",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites20.png",
    title: "Midori no Kemono Ninja Suit",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites21.png",
    title: "Shinto Ninja Suit",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites22.png",
    title: "Amaterasu Omikami Suit",
    vector: "/rarities-img/Epic-Vector.png",
  },
  {
    image: "/rarities-img/rarites23.png",
    title: "Dokeshi Ninja Suit",
    vector: "/rarities-img/Epic-Vector.png",
  },
];

export const raritiesFace = [
  {
    image: "/rarities-img/rarites24.png",
    title: "Smirky",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites25.png",
    title: "Mad",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites26.png",
    title: "Intimidating",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites27.png",
    title: "Furious",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites28.png",
    title: "Annoyed",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites29.png",
    title: "Confident",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites30.png",
    title: "Ninja Mask",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites31.png",
    title: "Green Eyes",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites32.png",
    title: "Brown Eyes",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites33.png",
    title: "Blue Eyes",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites34.png",
    title: "Eye Mask",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites35.png",
    title: "Crazy Cyan Eyes",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites36.png",
    title: "Crazy Green Eyes",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites37.png",
    title: "Crazy Red Eyes",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites38.png",
    title: "Crazy Purple Eyes",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites39.png",
    title: "Crazy Orange Eyes",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites40.png",
    title: "Red Kitsune Mask",
    vector: "/rarities-img/Epic-Vector.png",
  },
  {
    image: "/rarities-img/rarites41.png",
    title: "Blue Panda Mask",
    vector: "/rarities-img/Epic-Vector.png",
  },
];

export const raritiesHair = [
  {
    image: "/rarities-img/rarites42.png",
    title: "Brown Samurai Hair",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites43.png",
    title: "Black Samurai Hair",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites44.png",
    title: "Long Brown Hair",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites45.png",
    title: "Short Brown Hair",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites46.png",
    title: "Black Hair",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites47.png",
    title: "White Hair",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites48.png",
    title: "Grey Hair",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites49.png",
    title: "Blue Hair",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites50.png",
    title: "Orange Hair",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites51.png",
    title: "Green Hair",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites52.png",
    title: "Yellow Hair",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites53.png",
    title: "Cyan Hair",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites54.png",
    title: "Red Hair",
    vector: "/rarities-img/Epic-Vector.png",
  },
  {
    image: "/rarities-img/rarites55.png",
    title: "Purple Hair",
    vector: "/rarities-img/Epic-Vector.png",
  },
];

export const raritiesWeapons = [
  {
    image: "/rarities-img/rarites56.png",
    title: "Tonfas",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites57.png",
    title: "Sais",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites58.png",
    title: "Kunais",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites59.png",
    title: "Knuckle Blades",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites60.png",
    title: "Daggers",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites61.png",
    title: " Katanas",
    vector: "/rarities-img/Common-Vector.png",
  },
  {
    image: "/rarities-img/rarites62.png",
    title: "Flying Cakes",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites63.png",
    title: "Seinaru Daggers",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites64.png",
    title: "Raiton Kunais",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites65.png",
    title: "Shurikens",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites66.png",
    title: "Kasai Fists",
    vector: "/rarities-img/Uncommon-Vector.png",
  },
  {
    image: "/rarities-img/rarites67.png",
    title: "Yuki Sais",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites68.png",
    title: "Kasai Shurikens",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites69.png",
    title: "Golden Sickle & Chain",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites70.png",
    title: "Kasai Katanas",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites71.png",
    title: "Chakra Knuckle Blades",
    vector: "/rarities-img/Rare-Vector.png",
  },
  {
    image: "/rarities-img/rarites72.png",
    title: "Kaze Nunchuks",
    vector: "/rarities-img/Epic-Vector.png",
  },
  {
    image: "/rarities-img/rarites73.png",
    title: "Kasai Sickle & Chain",
    vector: "/rarities-img/Epic-Vector.png",
  },
];
