import React, {useMemo} from 'react';
import HeaderMain from './headerMain/headerMain';
import MainData from './mainData/mainData';
import OurStore from './main/ourStor';
import RoadMap from './roadMap/roadMap';
import Faq from './FAQ/faq';
import Mint from "../pages/mint/mint";
import {clusterApiUrl} from "@solana/web3.js";
import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletExtensionWallet,
    getSolletWallet
} from "@solana/wallet-adapter-wallets";
import {ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react";
import {WalletDialogProvider} from "@solana/wallet-adapter-material-ui";

const network = process.env.REACT_APP_SOLANA_NETWORK;

const Layout = () => {
  
  return (
    <>
      <HeaderMain />
      <MainData />
        <Mint />
      <OurStore />
      <RoadMap />
      <Faq />
    </>
  );
};


const LayoutWrapped = () => {
    const endpoint = useMemo(() => clusterApiUrl(network), []);

    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSlopeWallet(),
            getSolflareWallet(),
            getSolletWallet({ network }),
        ],
        []
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={true}>
                <WalletDialogProvider>
                    <Layout />
                </WalletDialogProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default LayoutWrapped;


