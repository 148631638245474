import React from "react";
import "./headerMain.css";
import Header from "../header/header";

const HeaderMain = () => {
  return (
    <>
      <Header />
      <div className="main-container scroll" id="home">
        <div className="main-box">
          <div className="header-image">
            <img alt="" src="/images/images.png" className="left-img" />
            <img
              alt=""
              src="/images/header-ninja.png"
              className="responcive-img"
            />
          </div>
          <div className="header-text-container">
            <div>
              <p className="title-top"> Meet the</p>
              <p className="title-button"> NINJA-PIXELS</p>
            </div>
            <div>
              <p className="main-text">
                NINJA-PIXELS is a collection of 10,000 unique NFTs on Solana
                blockchain that are{" "}
                <span className="m-text-span">completely free </span>
                with <span className="m-text-span">
                  ZERO mint cost
                </span> and <span className="m-text-span">ZERO royalties</span>
              </p>
              <p className="main-text">
                Ninja Protocol covered all costs so everybody can enjoy NFTs
                with
                <span className="m-text-span"> no barrier to entry</span>
              </p>
              <p className="main-text">
                No royalties system and starting price at 0 will allow community
                to capture all the value from NINJA-PIXELS and help it stay
                within
              </p>
              <p className="main-text">See you in the Dojo!</p>
              <div className="main-button-container">
                <a
                  className="main-button"
                  href="https://discord.gg/ninjaprotocol"
                  target="_blank"
                >
                  JOIN THE DOJO
                </a>
                <a
                  className="main-button"
                  href="https://market.ninjaprotocol.io/#/"
                  target="_blank"
                >
                  PIXEL MARKETPLACE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMain;
