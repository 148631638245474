import React, { useCallback, useState } from "react";
import Header from "../components/header/header";
import "./rarities.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  raritiesBakground,
  raritiesSuit,
  raritiesFace,
  raritiesHair,
  raritiesWeapons,
} from "./raritiesData/raritiesData";

export const Rarities = () => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);

  return (
    <>
      <Header />
      <div className="rarities-container">
        <div className="rarities-attributes-cotainer box">
          <div className="attributes-box">
            <div className="attributes">
              <p className="attributes-title">
                <span className="attributes-subtitle">
                  NINJA-PIXELS have 74 unique attributes
                </span>
              </p>
            </div>
            <div className="attributes-img">
              <div className="attributes-image">
                <div className="rariti-attribute">
                  {/* <img
                  alt=""
                  src="/rarities-img/rarities-attributes.png"
                  className="attributes-header-img"
                /> */}

                  <div className="rariti-attribute-cammon">
                    <div>
                      <img
                        src="/rarities-img/Common-diamond.png"
                        alt=""
                        className="Common-diamond diamond"
                      />
                      <span className="rariti-attribute-text">Common</span>
                    </div>
                    <img
                      src="/rarities-img/white.png"
                      alt=""
                      className="rariti-attribute-text-border"
                    />
                    <img
                      src="/rarities-img/white-2.png"
                      alt=""
                      className="rariti-attribute-text-border-2"
                    />
                  </div>
                  <div className="rariti-attribute-uncammon">
                    <div>
                      <img
                        src="/rarities-img/Uncommon-diamond.png"
                        alt=""
                        className="Uncommon-diamond diamond"
                      />
                      <span className="rariti-attribute-text">Uncommon</span>
                    </div>
                    <img
                      src="/rarities-img/blue.png"
                      alt=""
                      className="rariti-attribute-text-border"
                    />
                    <img
                      src="/rarities-img/blue-2.png"
                      alt=""
                      className="rariti-attribute-text-border-2"
                    />
                  </div>
                  <div className="rariti-attribute-rare">
                    <div>
                      <img
                        src="/rarities-img/Rare-diamond.png"
                        alt=""
                        className="Rare-diamond diamond"
                      />
                      <span className="rariti-attribute-text">Rare</span>
                    </div>
                    <img
                      src="/rarities-img/purple.png"
                      alt=""
                      className="rariti-attribute-text-border"
                    />
                    <img
                      src="/rarities-img/purple-2.png"
                      alt=""
                      className="rariti-attribute-text-border-2"
                    />
                  </div>
                  <div className="rariti-attribute-epic">
                    <div>
                      <img
                        src="/rarities-img/Epic-diamond.png"
                        alt=""
                        className="Epic-diamond diamond"
                      />
                      <span className="rariti-attribute-text">Epic</span>
                    </div>
                    <img
                      src="/rarities-img/cyan.png"
                      alt=""
                      className="rariti-attribute-text-border"
                    />
                    <img
                      src="/rarities-img/cyan-2.png"
                      alt=""
                      className="rariti-attribute-text-border-2"
                    />
                  </div>
                </div>
                <img
                  className="rariti-attribute-img"
                  src="/rarities-img/Rectangle 92.png"
                  alt=""
                />
                <img
                  className="rariti-attribute-img"
                  src="/rarities-img/Rectangle 93.png"
                  alt=""
                />
                <img
                  className="rariti-attribute-img"
                  src="/rarities-img/Rectangle 94.png"
                  alt=""
                />
                {/* <img
                alt=""
                src="/rarities-img/rarities-res.png"
                className="attributes-res-img"
              /> */}
              </div>
            </div>
            <menu className="nav-menu">
              <div className="nav-menu-box nav-background-box">
                <a href="#ninja-backgraund">Background</a>
              </div>
              <div className="nav-menu-box">
                <a href="#ninja-suit">Suit</a>
              </div>
              <div className="nav-menu-box">
                <a href="#ninja-face">Face</a>
              </div>
              <div className="nav-menu-box">
                <a href="#ninja-hair">Hair</a>
              </div>
              <div className="nav-menu-box">
                <a href="#ninja-weapon">Weapon</a>
              </div>
            </menu>
          </div>
        </div>
        <div className="rarities-background-cotainer box" id="ninja-backgraund">
          <div className="background-box">
            <div className="background-title-box">
              <p className="background-title">Ninja Background</p>
            </div>
            <div className="container-box">
              {raritiesBakground.map((raritie, index) => {
                return (
                  <div className="raritie-box" key={index}>
                    <div className="raritie-img">
                      <Zoom
                        overlayBgColorStart="rgba(0, 0, 0, 0.9)"
                        overlayBgColorEnd="rgba(0, 0, 0, 0.9)"
                      >
                        <img
                          alt=""
                          src={raritie?.image}
                          onLoad={handleImgLoad}
                          width={isZoomed ? "100%" : "50%"}
                        />
                      </Zoom>
                    </div>
                    <p className="raritie-title">{raritie.title}</p>
                    <div className="raritie-vector">
                      <img src={raritie?.vector} alt="" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="rarities-suit-cotainer box" id="ninja-suit">
          <div className="background-box">
            <div className="suit-title-box">
              <p className="background-title">
                Ninja <span className="title-text">Suit</span>
              </p>
            </div>
            <div className="container-box">
              {raritiesSuit.map((raritie, index) => {
                return (
                  <div className="raritie-box" key={index}>
                    <div className="raritie-img">
                      <Zoom
                        overlayBgColorStart="rgba(0, 0, 0, 0.9)"
                        overlayBgColorEnd="rgba(0, 0, 0, 0.9)"
                      >
                        <img
                          alt=""
                          src={raritie?.image}
                          onLoad={handleImgLoad}
                          width={isZoomed ? "100%" : "50%"}
                        />
                      </Zoom>
                    </div>
                    <p
                      className="raritie-title"
                      // style={{ color: raritie.color }}
                    >
                      {raritie.title}
                    </p>
                    <div className="raritie-vector">
                      <img src={raritie?.vector} alt="" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="rarities-face-cotainer box" id="ninja-face">
          <div className="background-box">
            <div className="face-title-box">
              <p className="background-title">
                Ninja <span className="title-text">Face</span>
              </p>
            </div>
            <div className="container-box">
              {raritiesFace.map((raritie, index) => {
                return (
                  <div className="raritie-box" key={index}>
                    <div className="raritie-img">
                      <Zoom
                        overlayBgColorStart="rgba(0, 0, 0, 0.9)"
                        overlayBgColorEnd="rgba(0, 0, 0, 0.9)"
                      >
                        <img
                          alt=""
                          src={raritie?.image}
                          onLoad={handleImgLoad}
                          width={isZoomed ? "100%" : "50%"}
                        />
                      </Zoom>
                    </div>
                    <p
                      className="raritie-title"
                      // style={{ color: raritie.color }}
                    >
                      {raritie.title}
                    </p>
                    <div className="raritie-vector">
                      <img src={raritie?.vector} alt="" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="rarities-hair-cotainer box" id="ninja-hair">
          <div className="background-box">
            <div className="hair-title-box">
              <p className="background-title">
                Ninja <span className="title-text">Hair</span>
              </p>
            </div>
            <div className="container-box">
              {raritiesHair.map((raritie, index) => {
                return (
                  <div className="raritie-box" key={index}>
                    <div className="raritie-img">
                      <Zoom
                        overlayBgColorStart="rgba(0, 0, 0, 0.9)"
                        overlayBgColorEnd="rgba(0, 0, 0, 0.9)"
                      >
                        <img
                          alt=""
                          src={raritie?.image}
                          onLoad={handleImgLoad}
                          width={isZoomed ? "100%" : "50%"}
                        />
                      </Zoom>
                    </div>
                    <p
                      className="raritie-title"
                      // style={{ color: raritie.color }}
                    >
                      {raritie.title}
                    </p>
                    <div className="raritie-vector">
                      <img src={raritie?.vector} alt="" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="rarities-weapons-cotainer box" id="ninja-weapon">
          <div className="background-box">
            <div className="weapons-title-box">
              <p className="background-title">
                Ninja <span className="title-text">Weapons</span>
              </p>
            </div>
            <div className="container-box">
              {raritiesWeapons.map((raritie, index) => {
                return (
                  <div className="raritie-box" key={index}>
                    <div className="raritie-img">
                      <Zoom
                        overlayBgColorStart="rgba(0, 0, 0, 0.9)"
                        overlayBgColorEnd="rgba(0, 0, 0, 0.9)"
                      >
                        <img
                          alt=""
                          src={raritie?.image}
                          onLoad={handleImgLoad}
                          width={isZoomed ? "100%" : "50%"}
                        />
                      </Zoom>
                    </div>
                    <p
                      className="raritie-title"
                      // style={{ color: raritie.color }}
                    >
                      {raritie.title}
                    </p>
                    <div className="raritie-vector">
                      <img src={raritie?.vector} alt="" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rarities;
