import React, { useState, useEffect } from "react";
import "./mainData.css";
import calculateTimeLeft from "../timer/calculateTimeLeft";

const MainData = () => {
  const necessaryDate = `12/30/2021`;
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(necessaryDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(necessaryDate));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="header-date-container scroll">
      <div className="header-box">
        <div className="header-date-box">
          <img
            src="/images/20 Pixels_4k.jpg"
            alt=""
            width="100%"
            className="img-4k"
          />
          <img
            src="/images/20 Pixels_2k.jpg"
            alt=""
            width="100%"
            className="img-2k"
          />
          <img
            src="/images/20 Pixels_portrait_FHD.jpg"
            alt=""
            width="100%"
            className="img-FHD"
          />
          <img
            src="/images/20 Pixels_portrait_HD.jpg"
            alt=""
            width="100%"
            className="img-HD"
          />
        </div>
        {/* <div className='date-container'>
          <h3 className='date-title'>
            <span>DROPDATE</span> - DECEMBER 31 - 0AM CET
          </h3>
          <div className='date-box'>
            <div className='date-number'>
              {timeLeft.days} <p className='date-text'>DAYS</p>
            </div>
            <span className='date-stroke'>:</span>
            <div className='date-number'>
              {timeLeft.hours} <p className='date-text'>Hours</p>
            </div>
            <span className='date-stroke'>:</span>
            <div className='date-number'>
              {timeLeft.minutes} <p className='date-text'>Minutes</p>
            </div>
            <span className='date-stroke'>:</span>
            <div className='date-number'>
              {timeLeft.seconds} <p className='date-text'>Seconds</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MainData;
