import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import "./faq.css";

const Faq = () => {
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [question5, setQuestion5] = useState(false);
  const [question6, setQuestion6] = useState(false);
  const [question7, setQuestion7] = useState(false);
  const [question8, setQuestion8] = useState(false);
  const [question9, setQuestion9] = useState(false);
  const [question10, setQuestion10] = useState(false);

  const setAccordionItem = (id) => {
    switch (id) {
      case 1:
        setQuestion1(!question1);
        break;
      case 2:
        setQuestion2(!question2);
        break;
      case 3:
        setQuestion3(!question3);
        break;
      case 4:
        setQuestion4(!question4);
        break;
      case 5:
        setQuestion5(!question5);
        break;
      case 6:
        setQuestion6(!question6);
        break;
      case 7:
        setQuestion7(!question7);
        break;
      case 8:
        setQuestion8(!question8);
        break;
      case 9:
        setQuestion9(!question9);
        break;
      case 10:
        setQuestion10(!question10);
        break;
      default:
        return;
    }
  };

  const accordionData = [
    {
      id: 1,
      title: "How many NINJA-PIXELS will there be?",
      content: `There will be 10,000 NINJA-PIXELS in this collection.`,
      item: question1,
    },
    {
      id: 2,
      title: "How can I mint a NINJA-PIXEL?",
      content: (
        <div>
          You need a $PIXEL token to mint a NINJA-PIXEL - 1 $PIXEL mint token
          for 1 NINJA-PIXEL NFT. For more information regarding $PIXEL please
          read{" "}
          <a
            href="https://medium.com/@NinjaSolProto/introducing-ninja-pixels-4931d6a9695a"
            className="market-link"
            target="_blank"
          >
            this article
          </a>
        </div>
      ),
      item: question2,
    },
    {
      id: 3,
      title: "How can I get a $PIXEL token?",
      content: (
        <div>
          There are 3 ways you can get a $PIXEL token:
          <ul>
            <li className="question-li">
              Participate in Ninja Protocol and its partners’ events, contests,
              and giveaways that happen regularly (pay attention to Twitter)
            </li>
            <li className="question-li">
              Interact within the Ninja Protocol ecosystem, look for giveaway
              opportunities in the Discord community{" "}
            </li>
            <li className="question-li">
              Buy on the{" "}
              <a
                href="https://dex.ninjaprotocol.io/#/market/H3N8DN8DWH3FXDRhN38Yv9BgmBWVKmZz8QVkmdBjgRmv"
                className="market-link"
                target="_blank"
              >
                $PIXEL/$NINJA live Serum market
              </a>{" "}
              from other users
            </li>
          </ul>
        </div>
      ),
      item: question3,
    },
    {
      id: 4,
      title: "When is the NFT mint happening?",
      content: `The mint is live and ongoing in 4 batches of 2500. If you manage to get hold of a $PIXEL, you can mint at any time.`,
      item: question4,
    },
    {
      id: 5,
      title: "Will there be royalties on the NINJA-PIXEL NFTs?",
      content: `No royalties. 0%.`,
      item: question5,
    },
    {
      id: 6,
      title: "Can I mint the NFT on mobile?",
      content:
        "Currently you can only mint the NFT on desktop. When more reliable/trustworthy SOL mobile wallet options become available we might add the mint feature to mobile.",
      item: question6,
    },
    {
      id: 7,
      title:
        "When I try to mint I get an error message saying “Account does not have correct owner”?",
      content: (
        <div>
          This is likely due to 1 of 2 reasons:
          <ul>
            <li className="question-li">
              You don’t have a $PIXEL or enough $SOL in your wallet. The
              solution is to make sure you have at least 1 $PIXEL and the
              minimum $SOL required for transaction cost (we recommend ~0.02
              $SOL) in your wallet.
            </li>
            <li className="question-li">
              You bought a $PIXEL from the NINJA-DEX on Serum and it created two
              $PIXEL addresses in your wallet. The easiest way to fix this is
              simply to transfer your $PIXEL to a new wallet and mint from
              there.
            </li>
          </ul>
        </div>
      ),
      item: question7,
    },
    {
      id: 8,
      title: "Where can I trade NINJA-PIXEL NFTs?",
      content: (
        <div>
          Currently NINJA-PIXELS are exclusively traded on Ninja Protocol’s
          flagship{" "}
          <a
            href="https://market.ninjaprotocol.io/#/"
            className="market-link"
            target="_blank"
          >
            NFT marketplace.
          </a>
        </div>
      ),
      item: question8,
    },
    {
      id: 9,
      title: "How can I check the trait rarities of my NINJA-PIXEL?",
      content: (
        <div>
          You can find the list of trait rarities on the{" "}
          <a href="/Rarities" className="market-link">
            Rarities page
          </a>
          . You can also check the overall rarity ranking of your NINJA-PIXEL on
          the{" "}
          <a
            href="https://market.ninjaprotocol.io/#/"
            className="market-link"
            target="_blank"
          >
            Ninja Market
          </a>{" "}
          (note that this ranking will change as more NINJA-PIXELS become
          minted).
        </div>
      ),
      item: question9,
    },
    {
      id: 10,
      title: "What is $ONI?",
      content: (
        <div>
          It's a deflationary consumable in-game currency of the upcoming NINJA-PIXELS mobile game. For more information please{" "}
          <a
            href="https://medium.com/@NinjaSolProto/the-token-ecosystem-of-ninja-protocol-785425c7da2b/"
            className="market-link"
            target="_blank"
          >
            read this
          </a>{" "}
        </div>
      ),
      item: question10,
    },
  ];

  return (
    <div className="faq-container" id="FAQ">
      <div className="faq-box scroll">
        <div className="faq-title-box faq-title">
          <p>FAQ</p>
        </div>
        {accordionData.map(({ id, title, content, item }) => (
          <Accordion className="question-list">
            <AccordionSummary
              onClick={() => setAccordionItem(id)}
              expandIcon={
                item ? (
                  <span className="question-icon">-</span>
                ) : (
                  <span className="question-icon">+</span>
                )
              }
            >
              <Typography className="question-title">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="question-content">
                <p>{content}</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default Faq;
