import React from "react";
import Rarities from "./pages/rarities";
import Layout from "./components/Layout";
import Footer from "./components/footer/footer";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Mint from "./pages/mint/mint";
import Winners from "./pages/winners";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Layout} />
        <Route exact path="/Rarities" component={Rarities} />
        <Route exact path="/Mint" component={Mint} />
        <Route exact path="/Winners" component={Winners} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
