import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import "./header.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import {WalletDialogButton, WalletDisconnectButton, WalletConnectButton} from "@solana/wallet-adapter-material-ui";
import {useAnchorWallet} from "@solana/wallet-adapter-react";
import {shortenAddress} from "../../candy-machine";

const ConnectButton = styled(WalletDialogButton)`
  font-weight: bolder;
  cursor: pointer;
  background: linear-gradient(
      180deg, #0080ff 0%, #1cc4c4 100%);
  border: none;
  box-shadow: 7px 4px 4px rgb(0 0 0 / 25%);

  border-radius: 4px;
  color: #ffffff;
  transition-property: opacity;
  transition-duration: 0.16s;
  transition-timing-function: ease-in;
`;

const Header = (children) => {
  const [isActive, setActive] = useState("Home");
  const [showElement, setShowElement] = useState(false);
  const [hamburgerElement, setHamburgerElement] = useState(false);

  const history = useHistory();
  const location = history.location.pathname;

  const activeTag = (tag) => {
    const tagValue = tag.target;
    setActive(tagValue.innerHTML);
  };

  const hendleWithMenu = () => {
    setHamburgerElement(!hamburgerElement);
  };

  window.addEventListener("scroll", () => {
    const scroll = document.documentElement.scrollTop;
    const nav = document.querySelector(".header-container");

    // Mobile version
    if (isMobile) {
      if (scroll >= 100 && scroll <= 300) {
        nav.classList.add("active-nav");
      } else if (scroll <= 160) {
        nav.classList.remove("active-nav");
      } else if (scroll >= 500) {
        nav.classList.add("active-nav");
      }
      if (scroll >= 100 && scroll <= 500) {
        setActive("Home");
      } else if (scroll >= 1385 && scroll <= 1500) {
        setActive("Our Story");
      } else if (scroll >= 2263 && scroll <= 2400) {
        setActive("Roadmap");
      } else if (scroll >= 3683 && scroll <= 3500) {
        setActive("FAQ");
      } else if (scroll >= 3500 && scroll <= 4100) {
        setActive("Social");
      }
    } 
    else {
      // PC version
      if (scroll >= 120 && scroll <= 500) {
        nav.classList.add("active-nav");
      } else if (scroll <= 160) {
        nav.classList.remove("active-nav");
      } else if (scroll >= 500) {
        nav.classList.add("active-nav");
      }
      if (scroll >= 100 && scroll <= 500) {
        setActive("Home");
      } else if (scroll >= 1200 && scroll <= 1500) {
        setActive("Our Story");
      } else if (scroll >= 2210 && scroll <= 2600) {
        setActive("Roadmap");
      } else if (scroll >= 3100 && scroll <= 3400) {
        setActive("FAQ");
      } else if (scroll >= 3900 && scroll <= 4500) {
        setActive("Social");
      }
    }
  });

  const wallet = useAnchorWallet();

  return (
    <div className="header">
      <div className="header-container">
        <div className="header-logo">
          <div className="logo-box">
            <a href="/" onClick={() => setActive("Home")}>
              <img alt="" src="/images/pixil-logo.png" />
            </a>
          </div>
        </div>
        {location === "/" ? (
          <nav
            className={
              hamburgerElement
                ? "navbar-active"
                : "navbar-container hamburger-media"
            }
          >
            <div className="navbar-container-box">
              <ul className="navbar-text">
                <li className="navbar" onClick={activeTag}>
                  <a
                    href="#home"
                    className={isActive === "Home" ? "active" : "notActive"}
                  >
                    Home
                  </a>
                </li>
                <li className="navbar" onClick={activeTag}>
                  <a
                    href="#ourStory"
                    className={
                      isActive === "Our Story" ? "active" : "notActive"
                    }
                  >
                    Our Story
                  </a>
                </li>
                <li className="navbar" onClick={activeTag}>
                  <a
                    href="#roadMap"
                    className={isActive === "Roadmap" ? "active" : "notActive"}
                  >
                    Roadmap
                  </a>
                </li>
                <li className="navbar" onClick={activeTag}>
                  <a
                    href="#FAQ"
                    className={isActive === "FAQ" ? "active" : "notActive"}
                  >
                    FAQ
                  </a>
                </li>
                <li className="navbar" onClick={activeTag}>
                  <a
                    href="#social"
                    className={isActive === "Social" ? "active" : "notActive"}
                  >
                    Socials
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        ) : location === "/Rarities" ? (
          <p className="rarities-title">Ninja Rarities</p>
        ) : (
          <>
            <div style={{ width: "52.5%" }}></div>
            <div className="winners-title-container">
              <p className="winners-title">$PIXEL by Ninja Protocol</p>
            </div>
          </>
        )}
        <div className={location === "/" ? "menu-logo-container" : "disp-none"}>
          <div className={location === "/" ? "header-image-box" : ""}>
            {location === "/" && (
              <>
                <Link to={"/Rarities"}>
                  <div className="rarities-text">
                    <p
                      onMouseOver={() => {
                        setShowElement(!showElement);
                      }}
                    >
                      Rarities
                    </p>
                  </div>
                </Link>
                <span className="rarities-winner">|</span>
                <Link to={"/Winners"} style={{ margin: " 0 10px 0 0" }}>
                  <div className="rarities-text">
                    <p
                      onMouseOver={() => {
                        setShowElement(!showElement);
                      }}
                    >
                      Winners
                    </p>
                  </div>
                </Link>
                <div className={"wallet-container"}>
                { wallet? <WalletDisconnectButton />   :<ConnectButton> Connect Wallet </ConnectButton>}
                </div>
              </>
            )}
          </div>
          {location === "/" ? (
            <div className="hamburger-menu-box" onClick={hendleWithMenu}>
              <div
                className={hamburgerElement ? "menu-box-rotate" : "menu-box"}
              >
                <span
                  className={hamburgerElement ? "row-top-active" : "row-top"}
                ></span>
                <span
                  className={
                    hamburgerElement ? "row-middle-active" : "row-middle "
                  }
                ></span>
                <span
                  className={
                    hamburgerElement ? "row-bottom-active" : "row-bottom"
                  }
                ></span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
