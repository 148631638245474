import React from "react";
import Header from "../components/header/header";
import { Link } from "react-router-dom";
import "./winners.css";

export const Winners = () => {
  return (
    <>
      <Header />
      <div className="winners-container">
        <div className="youtube-content">
          <iframe
            className="youtubeIframe"
            src="https://www.youtube.com/embed/J3EobJk-BHQ"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="winners-child-container">
          <div className="winners-leaderboard">
            <h1>$PIXEL Token Winners Leaderboard</h1>
          </div>
          <div className="datastudio-content">
            <iframe
              className="dataIframe"
              src="https://datastudio.google.com/embed/reporting/6f49ae0c-482a-4d4d-910c-4ab083861dd1/page/A61TC"
              frameBorder="0"
              allowFullScreen
            />
          </div>
          <div className="link">
            <Link
              target="_blank"
              to={{
                pathname:
                  "https://medium.com/@NinjaSolProto/introducing-ninja-pixels-4931d6a9695a",
              }}
              className="link-container"
            >
              <div className="link-content-container">
                <div className="link-content">
                  <div className="medium-icon-container">
                    <div className="medium-icon" />
                  </div>
                  <div>
                    <div className="content-first-child">
                      Introducing: NINJA-PIXELS | EN, 日本語, 中文
                    </div>
                    <div className="content-second-child">Medium</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Winners;
